.core-badge {
  background-color: #979595;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 6px;
  height: max-content;
  font-family: Mulish , sans-serif;
  font-weight: 700;
  letter-spacing: 0.005em;

  &.primary {
    background-color: #1e88e5
  }

  &.secondary {
    background-color: #004c96;
  }

  &.success {
    background-color:  #47AF35;
  }

  &.error {
    background-color: #ff6550;
  }

  &.warning {
    background-color: #fb9d00;
  }

  &.info {
    background-color: #7aa9d9;
  }

  &.grey {
    background-color: #ACACAC;
  }

  &.blue {
    background-color: #226FC0;
  }

  &.red {
    background-color: #D92F18;
  }

  &.green {
    background-color: #47AF35;
  }
}
