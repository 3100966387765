.rs-timeline {

  .rs-timeline-item {
    .rs-timeline-item-dot {
      left: -5px;

      &:before {
        height: 20px;
        width: 20px;
        border: 2px solid #004C96;
        background: white;
      }
    }

    .rs-timeline-item-tail {
      background-color: #004C96;
    }
  }
}
