// Here you can add other styles
body {
  background-color: white;
}
.display-4 {
  font-size: 1.8rem;
}
.display-4 .font-icon {
  font-size: 1.4rem;
  color: #424242;
}
#searchbar {
  top: 1rem;
}
#searchbar .fa-search {
  height: 2.2rem;
  line-height: 2.2rem;
}
.hr-value,
.stress-value,
.location-value {
  color: white;
  font-weight: bold;
  text-align: center;
  align-items: center;
  font-size: 0.7rem;
}
.hr-reading-container {
  position: absolute;
  margin-top: -3rem;
  margin-left: 29%;
  margin-left: 1.7rem;
  text-align: center;
}
.card {
  box-shadow: 0px 0px 4px 1px #eeeeee;
}
.dashboard-title {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}
.clickable {
  cursor: pointer !important;
  white-space: nowrap;
}
.selected{
  background-color: #CEE6FE;
  color: #004C96;
}
.patient-details {
  font-weight: normal;
}
.patient-details p span {
  font-weight: 600;
}
.patient-name-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
}
.title-text {
  font-size: 13px;
  font-weight: 400;
  font-family: Muli, sans-serif;
  color: #696F75;
  margin-bottom: 0 !important;
}
.title-text-bold-blue {
  font-size: 13px;
  font-weight: 700;
  font-family: Muli, sans-serif;
  color: #033761;
  margin-bottom: 5 !important;
}
.value-text {
  font-size: 14px;
  // font-weight: 700;
  font-family: Muli, sans-serif;
  color: #000;
  word-break: break-all;
  white-space: normal;
}
.card-body {
  padding-bottom: 0;
}
.edit-pencil-icon {
  position: absolute;
  margin-top: -2.2rem;
  right: 0.5rem;
  font-size: 0.75rem;
  color: grey;
}
.blue-text {
  color: #033761 !important;
}
.red-text {
  color: red !important;
}
.patient-info-container p {
  margin-bottom: 0.5rem;
}
.patient-info {
  color: #022C4E;
}
.battery-container {
  font-weight: 600;
  position: absolute;
  right: 1.5rem;
  margin-top: -4rem;
}
.battery-percentage {
  color: #47af35;
}
.matric-icon {
  text-align: center;
  font-size: 3rem;
  float: right;
}
.heart-health,
.red-text {
  color: #f44336;
}
.grey-text {
  color: #BABABA  !important;
  font-weight: normal !important;
}
.matric-value span {
  font-size: 1rem;
}
.matric-value {
  font-size: 1rem;
  font-weight: bold;
}
.yellow-text,
.stress-level-icon {
  color: #ffb247;
}
.small-text {
  font-weight: normal;
  font-size: 1rem;
}
.card-p {
  margin-bottom: 0.3rem;
}
.bold-text {
  font-weight: 700;
}
.semi-bold-text {
  font-weight: 600;
}
.container-fluid {
  overflow: hidden;
}
.ECG-viewer-heading {
  background-color: white;
}
.sidebar,
.sidebar .nav,
.sidebar .sidebar-nav,
.sidebar .sidebar-footer {
  background-color: #033761;
  transition: transform 1s ease-in-out;
}
.sidebar-expanded {
  width: 225px !important;
  overflow: hidden;
}

.sidebar-expanded .navigation-container {
  overflow-y: scroll;
}

.navigation-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.navigation-container div .active{
  background-color: #109fd6;
}
.navigation-row {
  border-radius: 20px !important;
  margin: 10px 0px !important;
  height: 35px !important;
  width: 35px !important;
  display: flex !important;
  justify-content: center !important;
}
.navigation-icon {
  font-size: 18px;
  color: white,
}
.navigation-link {
  margin: 0px;
  padding-left: 10px;
  font-size: 15px;
}
.main {
  background-color: #FAFAFA;
}
@media only screen and (min-width: 992px) {
  .main,
  .app-footer {
    margin-left: 225px !important;
  }
  .collapsed-sidebar{
    margin-left: 100px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .slider {
    width: 90px;
  }
  .matric-value {
    white-space: nowrap;
    font-size: 0.85rem;
  }
  .card-p {
    font-size: 0.7rem;
    white-space: nowrap;
  }
  .small-text {
    font-size: 0.65rem !important;
  }
  .patient-details p,
  .patient-details p span {
    white-space: nowrap;
    font-size: 0.7rem;
  }
  th,
  .sort-icon {
    font-size: 0.85rem !important;
  }
  .highcharts-axis-labels text tspan {
    font-size: 7px !important;
  }
}
@media only screen and (max-width: 1300px) {
  .highcharts-axis-labels text tspan {
    font-size: 8px !important;
  }
}
.slider {
  width: 120px;
}
.sidebar-collapsed{
  width: 99px !important;
  overflow: hidden;
}
.sidebar-nav {
  width: 100% !important;
}
.nav-profile {
  box-shadow: none;
  height: auto;
  background-color: transparent;
  border: none;
}
.profile-img {
  // margin-top: 0.5rem;
  border-radius: 100%;
}
.profile-collapsed{
  height: 68px;
  width: 68px;
}
.profile-expanded{
  height: 90px;
  width: 90px;
}
.white-text {
  color: white;
}
.profile-active-icon {
  color: #4caf50;
  font-size: 0.6rem;
}
.profile-inactive-icon {
  color: #df4550;
  font-size: 0.6rem;
}
.tiny-text {
  font-size: 0.7rem;
}
.profile-line-p {
  margin-bottom: 0;
  text-align: center;
}
.active {
  background-color: #134167 !important;
}
html:not([dir="rtl"]) .sidebar {
  margin-left: -225px;
}
.location-value {
  height: 100%;
}
* {
  font-family: Muli, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Muli, sans-serif;
}
.table{
  margin-bottom: 0;
}
.ps-table-header,
.form-card-footer,
.form-card-header {
  border: none;
  color: #033761;
  background-color: white;
}
.ps-title {
  padding-top: 10px;
}
.patient-str {
  position: absolute;
  right: 250px;
  top: 25px;
  font-weight: bold;
  color: #696f75;
}
.form-control,
select {
  color: #022C4E;
  background-color: #f0f0f0;
}
.search-bar::placeholder {
  color: black;
}
td,
th {
  border-color: #f0f0f0 !important;
}
th {
  border-top: none !important;
  text-transform: uppercase;
  color: #109fd6;
}
.sort-icon {
  font-size: 0.85rem;
  margin-left: 0.5rem;
}
.battery-icon {
  color: #47af35;
  font-size: 1.2rem;
}
.ECG-chart-container {
  padding: 0;
}
.chart-card {
  padding-bottom: 15px;
}

.link-td {
  text-align: left;
}
.form-sub-heading {
  margin-top: 20px;
  margin-bottom: 20px;
  // text-transform: uppercase;
}
.profile-heading-text{
  font-size: 20px;
  line-height: 24px;
}

.outline-button {
  border: 1px solid #185fa5;
  background-color: #FFF;
  margin-right: 20px;
  padding-right: 30px;
  padding-left: 30px;
  font-weight: bold;
  color: #185fa5;
}
.outline-button:hover {
  background-color: #FFF;
  border: 1px solid #185fa5;
  color: #185fa5;
}
.border-bottom {
  border-bottom: 1px solid #99B7D5;
  margin-right: 50px;
  padding-bottom: 10px;
}
.user-form-card {
  padding-top: 0;
}
select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}
.form-label {
  color: #033761;
  // font-weight: bold;
}
.form-error-label{
  margin-top: 7px;
  color: #ef5350;
}
.form-card-footer {
  padding-bottom: 20px;
}
.primary-btn {
  background-color: #004C96;
  // margin-right: 20px;
  padding-right: 30px;
  padding-left: 30px;
  font-weight: bold;
  color: white;
}
.reset-btn {
  background-color: white;
  margin-right: 20px;
  padding-right: 30px;
  padding-left: 30px;
  font-weight: bold;
  border: 2px solid #185fa5;
  color: #185fa5;
}
#bo-input {
  background-color: transparent;
  display: inline-block;
  width: 30px;
  padding: 0;
  border: none;
  font-size: 1rem;
  color: #033761;
  font-weight: bold;
}

// CSS Range Slider - Start
.input-range__label-container {
  display: none;
}
.input-range__track {
  height: 0.6rem !important;
}
.input-range__slider {
  height: 0.6rem !important;
  width: 0.6rem !important;
  margin-top: -0.6rem !important;
  background-color: #185fa5;
}
.slidecontainer {
  margin-bottom: 10px;
}
.battery-img {
  width: 25px;
}
.patient-name-td, .align-left {
  text-align: left;
}
.afib-table-row {
  padding: 0.5rem !important;
}
.patient-name-td {
  font-size: 15px;
}
th {
  font-weight: normal;
}
.plotly-chart {
  height: 100%;
  width: 100%;
}
// Model design
.popup-overlay{
  z-index: 1020 !important;
  background-color: rgba(0,0,0,.3) !important;
}
.popup-content.ecg-popup-content {
  margin: auto;
  background: #fff;
  width: 90% !important;
  padding: 15px !important;
  overflow-y: scroll;
  max-height: 90%;
  border: 1px solid #d7d7d7;
}
.popup-content {
  margin: auto;
  background: #fff;
  width: 530px !important;
  padding: 15px !important;
  overflow-y: auto;
  max-height: 90%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}
.holter-popup-content {
  width: 450px !important;
}
.tag-popup-content {
  margin: auto;
  background: #fff;
  width: 420px !important;
  padding: 15px !important;
  overflow-y: auto;
  max-height: 90%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}
.delete-popup-content {
  margin: auto;
  background: #fff;
  width: 30% !important;
  padding: 15px !important;
  overflow-y: auto;
  max-height: 90%;
  border: 1px solid #d7d7d7;
  box-shadow: 1px 1px 10px lightgray;
}
.afib-popup-content {
  padding: 0px !important;
  width: 450px !important;
}
.afib-results-popup-content {
  width: 750px !important;
}
button {
  outline: none !important;
}
.popup-overlay {
  background: rgba(220,220,220, 0.7);
}
.ecg-modal {
  position: relative;
  display: block;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.delete-modal {
  position: relative;
  display: block;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.MuiTypography-body1 {
  font-size: 0.8rem !important;
}
.row-column {
  display: flex;
  flex-direction: column;
}
.form-label-bold {
  font-weight: 600;
  font-size: 15px;
}
select {
  cursor: pointer;
}
.symptom-details::-webkit-scrollbar{
  display: none;
}
.symptom-details-hover:hover{
  overflow-y: auto !important;
}
.search-bar {
  font-family: Muli;
  font-weight: 400;
}
.search-box::placeholder {
  color: #99B7D5;
}
.sdp--date-btn__selected {
  background-color: #033761 !important;
  border-radius: 0px !important;
}
.sdp--square-btn {
  box-shadow: none !important;
}
.hover-element:hover {
  background-color: #CEE6FE !important;
}
.hover-element-popup:hover {
  background-color: #F0F0F0 !important;
}
.hover-element-light:hover{
  background-color: #EEF4FC !important;
}
.custom-menu-item {
  display: block !important;
  width: 100% !important;
  text-align: left;
  color: #022C4E !important;
}
.custom-menu-item button {
  padding: 7px !important;
}
.menu-open {
  padding: 0 !important;
}
.remove-popup-content::-webkit-scrollbar {
  display: none;
}
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.min-height {
  height: 68.5px;
}
.analysis-input {
  background-color: #EDEDED;
  border: 0px;
}
.analysis-input:focus {
  background-color: #FFF;
  border: 0.5px solid #696F75;
}
.sdp--month-name {
  margin-bottom: 0px !important;
  color: #033761 !important;
  font-weight: 600 !important;
}
.sdp--square-btn__shadowed {
  color: #033761 !important;
}
.sdp--text {
  color: #033761;
}
.linear-gradiant {
  background: linear-gradient(#3370AB, #004C96, #002E5A);
  color: #FFF;
  font-weight: 600;
}
.linear-gradiant:hover {
  color: #FFF;
}
.css-b62m3t-container div {
  border-color: #99B7D5 !important;
  color: #022C4E;
}
textarea:focus, input:focus{
  outline: none;
}
.form-horizontal .form-group {
  margin-bottom: 0;
}
.custom-date-picker.no-date {
  .sdp--date-btn__selected {
    background-color: transparent !important;
    color: #033761 !important;
  }
}
.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.max-width {
  max-width: 287px;
}
.heading-text {
  font-family: Muli, sans-serif;
  line-height: 64px;
  font-weight: 700;
  font-size: 22px !important;
}
.sub-heading-text {
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-bottom: 20px;
}
.blue-afib-title {
  color: #022C4E !important; 
  line-height: 28px !important;
}
.afib-heading {
  padding-left: 30px;
  padding-right: 30px;
}
.blue-title-text {
  color: #033761 !important;
}
.menu-icon {
  width: 22px !important;
}
.react-horizontal-scrolling-menu--scroll-container {
  position: unset !important;
}

.pdf-label {
  font-family: Muli !important;
  font-weight: bold !important;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.button-disabled {
  background-color: #D1D1D1 !important;
  color: #979595 !important;
}

.button-disabled-outline {
  background-color: #fff !important;
  color: #979595 !important;
  border: 1px solid #979595 !important;
}

.metric-card--time {
  color: #022C4E;
  margin:0 !important;
  margin-top: 14px;
  margin-bottom: 0 !important;
  font-size: 12px;
}

.metric-card--timeperiod {
    color: #022C4E;
    margin: 0 !important;
    margin-bottom: 6px !important;
    font-size: 12px;
}

.metric-card--subtext{
  font-size: 12px;
  margin-bottom: 10px !important;
  color: #022C4E;
  font-weight: 400;
}

.metric-card--value{
    color: #022C4E;
  font-weight: bold;
}

.metric-card--unit {
    color: #022C4E;
  font-size: 11px;
}

.highcharts-credits {
  display: none;
  width:100%;
  overflow-x: auto;
}

.afib-table th, .afib-table td{
  padding: 0.65rem !important;
}

.afib-table td{
  text-align: left;
  vertical-align: middle;
}

.analysis-disabled-overlay {
  cursor: not-allowed;
  display:flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}